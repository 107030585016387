import React, { useEffect, useMemo, useRef } from 'react';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import { array, bool, func, node, object, oneOfType, shape, string } from 'prop-types';
import classNames from 'classnames';
import omit from 'lodash/omit';
import { propTypes, LISTING_STATE_CLOSED, LINE_ITEM_UNITS } from '../../util/types';
import { convertNumberToMoney, priceData } from '../../util/currency';
import { parse, stringify } from '../../util/urlHelpers';
import { ModalInMobile, PrimaryButton, SecondaryButton, IconSpinner } from '..';
import { LISTING_TYPE } from '../../constants/listingAttributes';
import BookingTimeForm, { getSessionsForSelectedDates } from './BookingTimeForm/BookingTimeForm';
import { CUSTOMER_FEE_PERCENTAGE } from '../../transactions/transactionsUtil';
import { getCustomerFee, getNormalizedFee } from '../../util/sessions';
import { useSelector } from 'react-redux';
import { dateIsBefore } from '../../util/dates';
import moment from 'moment';

import css from './OrderPanel.module.css';

// This defines when ModalInMobile shows content as Modal
const MODAL_BREAKPOINT = 1023;
const TODAY = new Date();

const openBookModal = (isOwnListing, isClosed, history, location) => {
  if (isOwnListing || isClosed) {
    window.scrollTo(0, 0);
  } else {
    const { pathname, search, state } = location;
    const searchString = `?${stringify({ ...parse(search), book: true })}`;
    history.push(`${pathname}${searchString}`, state);
  }
};

const closeBookModal = (history, location) => {
  const { pathname, search, state } = location;
  const searchParams = omit(parse(search), 'book');
  const searchString = `?${stringify(searchParams)}`;
  history.push(`${pathname}${searchString}`, state);
};

const dateFormattingOptions = { month: 'short', day: 'numeric', weekday: 'short' };

const getMinPriceFromTS = (timeSlots = [], duration) => {
  if (!timeSlots || timeSlots.length === 0) return null;

  let priceCurrency;

  const minAmount = timeSlots.reduce((minAmount, slot) => {
    const { price } = slot;

    const priceAmount = price?.amount || 0;
    priceCurrency = price?.currency;

    const amountWithDuration = priceAmount * duration;

    return Math.min(minAmount, amountWithDuration);
  }, 1000000);

  return convertNumberToMoney(minAmount, priceCurrency);
};

const getPriceWithFee = (price, feeAmount) => {
  if (!price) return;

  return convertNumberToMoney(price.amount + feeAmount, price.currency);
};

const BookingPanel = props => {
  const {
    rootClassName,
    className,
    listing,
    isOwnListing,
    unitType,
    onSubmit,
    onManageDisableScrolling,
    monthlyTimeSlots,
    history,
    location,
    intl,
    onFetchTransactionLineItems,
    lineItems,
    fetchLineItemsInProgress,
    fetchLineItemsError,
    onContactUser,
    isListingPendingApproval,
  } = props;

  const { currentUser } = useSelector(state => state.user);

  const { id, attributes, author } = useMemo(() => listing, [listing]);

  const { availabilityPlan, state, publicData } = useMemo(() => attributes, [attributes]);

  const {
    people = [],
    peopleAtMost,
    duration = 1,
    multiDaySessionStartIndex,
    type,
    sessionsDiscount,
    seasonalCharge,
    additionalPersonCharge,
  } = useMemo(() => publicData || {}, [publicData]);

  const isFreeListing = useMemo(() => type === LISTING_TYPE.FREE, [type]);

  const canShowAdults = useMemo(() => people.includes('adults'), [people]);
  const canShowChildren = useMemo(() => people.includes('children'), [people]);

  const isMultiDay = useMemo(() => duration > 1, [duration]);

  const minPriceBase = useMemo(() => getMinPriceFromTS(monthlyTimeSlots?.timeSlots, duration), [
    duration,
    monthlyTimeSlots?.timeSlots,
  ]);

  const isCurrentUserAuthor = currentUser?.id?.uuid === author?.id?.uuid;

  const buyerFee = isCurrentUserAuthor ? CUSTOMER_FEE_PERCENTAGE : getCustomerFee(currentUser);

  const feeAmount =
    minPriceBase?.amount * (getNormalizedFee(buyerFee, CUSTOMER_FEE_PERCENTAGE) / 100);

  const minPrice = getPriceWithFee(minPriceBase, feeAmount);

  const timeZone = availabilityPlan?.timezone;
  const hasListingState = !!state;
  const isClosed = hasListingState && state === LISTING_STATE_CLOSED;
  const showBookingTimeForm = hasListingState && !isClosed && minPrice && !isListingPendingApproval;
  const showClosedListingHelpText = id && isClosed;
  const { formattedPrice, priceTitle } = priceData(minPrice, intl, true);
  const isBook = !!parse(location.search).book;

  const pendingApprovalListing = useMemo(
    () => isListingPendingApproval && intl.formatMessage({ id: 'General.listingPendingApproval' }),
    [intl, isListingPendingApproval]
  );

  const subTitleText = showClosedListingHelpText ? (
    intl.formatMessage({ id: 'BookingPanel.subTitleClosedListing' })
  ) : isListingPendingApproval ? (
    <p>{pendingApprovalListing}</p>
  ) : null;

  const classes = classNames(rootClassName || css.root, className);

  const childrenInitialValue = useMemo(() => {
    if (!canShowChildren) return undefined;

    const isOnePersonSession = peopleAtMost < 2;

    // If the listing only has 1 seat, and we can select
    // adults, we should prefill children with 0
    if (canShowAdults && isOnePersonSession) return 0;

    return 1;
  }, [canShowAdults, canShowChildren, peopleAtMost]);

  const initialValues = useRef();

  useEffect(() => {
    // Google Things To Do requires us to pre-select the data for BookingTimeForm (closest cheapest price).
    const { gttd } = parse(location.search) || {};

    if (
      gttd &&
      (!initialValues.current ||
        (monthlyTimeSlots?.timeSlots?.length >= 1 && !initialValues.current?.dates))
    ) {
      const now = moment();
      const relevantTimeslots = monthlyTimeSlots?.timeSlots?.filter(
        timeslot => !dateIsBefore(timeslot.startsAt, now)
      );
      const chepestTimeslot = relevantTimeslots.reduce(
        (acc, curr) => (curr.price.amount < acc.price.amount ? curr : acc),
        relevantTimeslots.length > 0 ? relevantTimeslots[0] : {}
      );

      const startDate = chepestTimeslot?.startsAt;
      const endDate = chepestTimeslot?.endsAt;

      if (!startDate || !endDate) {
        initialValues.current = {
          adults: canShowAdults ? 1 : 0,
          children: childrenInitialValue,
        };
        return;
      }

      const sessions = getSessionsForSelectedDates(startDate, endDate, monthlyTimeSlots, timeZone);
      const chepestSession = sessions?.reduce(
        (acc, curr) => (curr.price < acc.price ? curr : acc),
        sessions.length > 0 ? sessions[0] : {}
      );

      initialValues.current = {
        adults: canShowAdults ? 1 : 0,
        children: canShowAdults ? 0 : childrenInitialValue,
        dates: { startDate, endDate },
        sessions: [chepestSession.value],
      };
    }
  }, [canShowAdults, childrenInitialValue, location.search, monthlyTimeSlots, timeZone]);

  const contactProviderButton = !!onContactUser && (
    <SecondaryButton
      type="button"
      onClick={onContactUser}
      className={css.contactUserButton}
      color="black"
    >
      <FormattedMessage id="General.contact" />
    </SecondaryButton>
  );

  return (
    <div className={classes}>
      <ModalInMobile
        containerClassName={css.modalContainer}
        id="BookingTimeFormInModal"
        isModalOpenOnMobile={isBook}
        onClose={() => closeBookModal(history, location)}
        showAsModalMaxWidth={MODAL_BREAKPOINT}
        onManageDisableScrolling={onManageDisableScrolling}
      >
        <div
          className={classNames(css.row, {
            [css.noMarginBottom]: pendingApprovalListing,
          })}
        >
          {!isFreeListing && !!formattedPrice && (
            <div className={css.priceContainer}>
              <h2 className={css.perUnit}>
                <FormattedMessage id="General.from" />
              </h2>
              <div className={css.priceValue} title={priceTitle}>
                {formattedPrice}
              </div>
            </div>
          )}
          <div>{subTitleText ? <div className={css.bookingHelp}>{subTitleText}</div> : null}</div>
        </div>

        {monthlyTimeSlots?.fetchTimeSlotsError ? (
          <div className={classNames(css.row, css.error)}>
            <FormattedMessage id="General.error" />
          </div>
        ) : null}

        {monthlyTimeSlots?.fetchTimeSlotsInProgress ? (
          <div className={css.spinner}>
            <IconSpinner />
          </div>
        ) : null}

        {showBookingTimeForm ? (
          <BookingTimeForm
            className={css.bookingForm}
            formId="BookingPanel"
            submitButtonWrapperClassName={css.submitButtonWrapper}
            unitType={unitType}
            onSubmit={onSubmit}
            initialValues={initialValues.current}
            price={minPrice}
            listingId={id}
            isOwnListing={isOwnListing}
            monthlyTimeSlots={monthlyTimeSlots}
            startDatePlaceholder={intl.formatDate(TODAY, dateFormattingOptions)}
            endDatePlaceholder={intl.formatDate(TODAY, dateFormattingOptions)}
            timeZone={timeZone}
            onFetchTransactionLineItems={onFetchTransactionLineItems}
            lineItems={lineItems}
            fetchLineItemsInProgress={fetchLineItemsInProgress}
            fetchLineItemsError={fetchLineItemsError}
            onManageDisableScrolling={onManageDisableScrolling}
            canShowChildren={canShowChildren}
            canShowAdults={canShowAdults}
            isMultiDay={isMultiDay}
            multiDaySessionStartIndex={multiDaySessionStartIndex}
            duration={duration}
            onContactUser={onContactUser}
            sessionsDiscount={sessionsDiscount}
            seasonalCharge={seasonalCharge}
            additionalPersonCharge={additionalPersonCharge}
            isFreeListing={isFreeListing}
            listingAuthor={author}
          />
        ) : !monthlyTimeSlots?.fetchTimeSlotsInProgress ? (
          <div className={css.bookingUnavailable}>
            <FormattedMessage id="General.bookingUnavailable" />
            {contactProviderButton}
          </div>
        ) : null}
      </ModalInMobile>
      <div className={css.openBookingForm}>
        {contactProviderButton}
        {showBookingTimeForm ? (
          <PrimaryButton
            type="button"
            onClick={() => openBookModal(isOwnListing, isClosed, history, location)}
          >
            <FormattedMessage id="General.book" />
          </PrimaryButton>
        ) : isClosed ? (
          <div className={css.closedListingButton}>
            <FormattedMessage id="BookingPanel.closedListingButtonText" />
          </div>
        ) : pendingApprovalListing ? (
          pendingApprovalListing
        ) : !monthlyTimeSlots?.fetchTimeSlotsInProgress ? (
          <div className={css.bookingUnavailable}>
            <FormattedMessage id="General.bookingUnavailable" />
          </div>
        ) : null}
      </div>
    </div>
  );
};

BookingPanel.defaultProps = {
  rootClassName: null,
  className: null,
  isOwnListing: false,
  unitType: LINE_ITEM_UNITS,
  monthlyTimeSlots: null,
  lineItems: null,
  fetchLineItemsError: null,
};

BookingPanel.propTypes = {
  rootClassName: string,
  className: string,
  listing: oneOfType([propTypes.listing, propTypes.ownListing]),
  isOwnListing: bool,
  unitType: propTypes.bookingUnitType,
  onSubmit: func.isRequired,
  authorDisplayName: oneOfType([node, string]).isRequired,
  onManageDisableScrolling: func.isRequired,
  monthlyTimeSlots: object,
  onFetchTransactionLineItems: func.isRequired,
  lineItems: array,
  fetchLineItemsInProgress: bool.isRequired,
  fetchLineItemsError: propTypes.error,

  onContactUser: func,

  // from withRouter
  history: shape({
    push: func.isRequired,
  }).isRequired,
  location: shape({
    search: string,
  }).isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

export default compose(
  withRouter,
  injectIntl
)(BookingPanel);
