import { useEffect, useMemo } from 'react';
import difference from 'lodash/difference';
import mapValues from 'lodash/mapValues';
import { LANGUAGE_CODES, LOCALE_CODE, getLocaleFromUrl } from '../constants/locales';
import useInterfaceLang from './useInterfaceLang';

// Flex template application uses English translations as default.
import enUSMessages from '../translations/en-US.json';
import frFRMessages from '../translations/fr-FR.json';
import deDEMessages from '../translations/de-DE.json';
import itITMessages from '../translations/it-IT.json';
import ListingService from '../services/ListingService';

const isTestEnv = process.env.NODE_ENV === 'test';

// If translation key is missing from `messagesInLocale` (e.g. fr.json),
// corresponding key will be added to messages from `defaultMessages` (en.json)
// to prevent missing translation key errors.
const addMissingTranslations = (sourceLangTranslations, targetLangTranslations) => {
  const sourceKeys = Object.keys(sourceLangTranslations);
  const targetKeys = Object.keys(targetLangTranslations);

  // if there's no translations defined for target language, return source translations
  if (targetKeys.length === 0) {
    return sourceLangTranslations;
  }
  const missingKeys = difference(sourceKeys, targetKeys);

  const addMissingTranslation = (translations, missingKey) => ({
    ...translations,
    [missingKey]: sourceLangTranslations[missingKey],
  });

  return missingKeys.reduce(addMissingTranslation, targetLangTranslations);
};

const getMessagesForLang = lang => {
  switch (lang) {
    case LOCALE_CODE.EN_US:
      return enUSMessages;
    case LOCALE_CODE.FR_FR:
      return frFRMessages;
    case LOCALE_CODE.DE_DE:
      return deDEMessages;
    case LOCALE_CODE.IT_IT:
      return itITMessages;
    default:
      return enUSMessages;
  }
};

const useTranslations = () => {
  const { changeLanguage, interfaceLang } = useInterfaceLang();

  const locale = getLocaleFromUrl();

  const baseTranslations = useMemo(() => enUSMessages, []);

  const hasBaseTranslations = baseTranslations && Object.values(baseTranslations).length > 0;

  const defaultTranslations = useMemo(
    () => (hasBaseTranslations ? baseTranslations : enUSMessages),
    [baseTranslations, hasBaseTranslations]
  );

  const messages = addMissingTranslations(defaultTranslations, getMessagesForLang(interfaceLang));

  // Locale should not affect the tests. We ensure this by providing
  // messages with the key as the value of each message.
  const testMessages = mapValues(messages, (val, key) => key);
  const localeMessages = isTestEnv ? testMessages : messages;

  useEffect(() => {
    LANGUAGE_CODES.forEach(lang => {
      ListingService.saveTitleMessages(lang, getMessagesForLang(lang));
    });
  }, []);

  return { interfaceLang, localeMessages, locale, changeLanguage };
};

export default useTranslations;
