import React, { useMemo } from 'react';
import { bool, string } from 'prop-types';
import { intlShape, injectIntl } from '../../util/reactIntl';
import classNames from 'classnames';
import { NamedLink, ResponsiveImage, ReviewRating } from '..';
import { lazyLoadWithDimensions } from '../../util/uiHelpers';
import { ensureListing, ensureUser } from '../../util/data';
import { propTypes } from '../../util/types';
import { convertNumberToMoney, priceData } from '../../util/currency';
import { LISTING_TYPE } from '../../constants/listingAttributes';
import SaveListingButton from '../SaveListingButton/SaveListingButton';
import { getCustomerFee, getNormalizedFee } from '../../util/sessions';
import { CUSTOMER_FEE_PERCENTAGE } from '../../transactions/transactionsUtil';

import css from './ListingCardCompact.module.css';
import { createURLParamsForListing } from '../../util/listingHelpers';

const ListingImage = props => <ResponsiveImage {...props} />;
const LazyImage = lazyLoadWithDimensions(ListingImage, { loadAfterInitialRendering: 3000 });

const getPriceWithFee = (price, feeAmount) => {
  if (!price) return;

  return convertNumberToMoney(price.amount + feeAmount, price.currency);
};

const ListingCardCompactComponent = props => {
  const {
    imageWrapperClassName,
    listing,
    showActivityLocation,
    showPrice,
    showRating,
    showSave,
    isHorizontal,
    intl,
    currentUser,
  } = props;

  const { id, attributes, images, author } = listing;
  const { title: listingTitle = '', price, publicData, deleted } = attributes;
  const { discipline, duration, activity, city, type, advancedAvailabilityPlan } = publicData || {};
  const firstImage = useMemo(() => (images?.length > 0 ? images[0] : null), [images]);

  const ensuredAuthor = useMemo(() => ensureUser(author), [author]);
  const { averageRating, ratingCount = 0 } = ensuredAuthor.attributes.profile.metadata || {};

  const isFree = useMemo(() => type === LISTING_TYPE.FREE, [type]);

  const isCurrentUserAuthor = currentUser?.id?.uuid === ensuredAuthor.id?.uuid;

  const minPriceBase = convertNumberToMoney(
    advancedAvailabilityPlan.entries.reduce((minAmount, entry) => {
      if (!duration) return minAmount;

      const amountWithDuration = entry.price * duration;

      return Math.min(minAmount, amountWithDuration);
    }, price?.amount),
    price?.currency
  );

  const buyerFee = isCurrentUserAuthor ? CUSTOMER_FEE_PERCENTAGE : getCustomerFee(currentUser);

  const feeAmount =
    minPriceBase?.amount * (getNormalizedFee(buyerFee, CUSTOMER_FEE_PERCENTAGE) / 100);

  const minPrice = getPriceWithFee(minPriceBase, feeAmount);

  const { formattedPrice } = useMemo(() => priceData(minPrice, intl), [intl, minPrice]);

  const messages = useMemo(
    () => ({
      activity: activity && intl.formatMessage({ id: `General.${activity}` }),
      discipline: discipline && intl.formatMessage({ id: `General.${discipline}` }),
      duration: duration && intl.formatMessage({ id: 'General.days' }, { numOfDays: duration }),
      free: intl.formatMessage({ id: 'General.free' }),
      priceFrom: intl.formatMessage(
        { id: 'General.priceFrom' },
        { formattedPrice: <span className={css.activeText}>{formattedPrice}</span> }
      ),
    }),
    [activity, intl, discipline, duration, formattedPrice]
  );

  return (
    <>
      <div
        className={classNames(
          css.imageWrapper,
          { [css.imageWrapperHorizontal]: isHorizontal },
          imageWrapperClassName
        )}
      >
        <div className={css.aspectWrapper}>
          <LazyImage
            rootClassName={css.rootForImage}
            alt={listingTitle || ''}
            image={firstImage}
            variants={['landscape-crop', 'landscape-crop2x']}
          />
          {showSave && !deleted && (
            <SaveListingButton rootClassName={css.heartButton} listingId={id.uuid} />
          )}
        </div>
      </div>

      <div className={css.infoWrapper}>
        <div>
          <div className={css.activityName}>{messages.discipline}</div>
          {showActivityLocation && <div className={css.activityPlaceName}>{city}</div>}
        </div>
        <div className={css.bottomWrapper}>
          {showRating && averageRating > 0 ? (
            <ReviewRating rating={averageRating} ratingCount={ratingCount} isAccented />
          ) : null}
          {duration && <div className={css.duration}>{messages.duration}</div>}
          {showPrice && (
            <div className={css.price}>
              {isFree ? (
                <span className={css.activeText}>{messages.free}</span>
              ) : (
                <span>{messages.priceFrom}</span>
              )}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

ListingCardCompactComponent.defaultProps = {
  rootClassName: null,
  className: null,
  imageWrapperClassName: null,
  showActivityLocation: true,
  showPrice: true,
  showRating: false,
  showSave: true,
  isHorizontal: false,
};

ListingCardCompactComponent.propTypes = {
  rootClassName: string,
  className: string,
  imageWrapperClassName: string,
  listing: propTypes.listing.isRequired,
  showActivityLocation: bool,
  showPrice: bool,
  showRating: bool,
  isHorizontal: bool,

  intl: intlShape.isRequired,
};

const ListingCardCompact = props => {
  const { rootClassName, className, listing, isHorizontal, ...rest } = props;
  const classes = useMemo(
    () => classNames(rootClassName || css.root, { [css.horizonalCard]: isHorizontal }, className),
    [className, isHorizontal, rootClassName]
  );
  const ensuredListing = useMemo(() => ensureListing(listing), [listing]);
  const { attributes } = ensuredListing;

  const { deleted } = attributes;

  const namedLinkProps = useMemo(
    () =>
      deleted ? {} : { name: 'ListingPage', params: createURLParamsForListing(ensuredListing) },
    [deleted, ensuredListing]
  );

  return deleted ? (
    <div className={classes}>
      <ListingCardCompactComponent listing={ensuredListing} isHorizontal={isHorizontal} {...rest} />
    </div>
  ) : (
    <NamedLink {...namedLinkProps} className={classes} target="_blank">
      <ListingCardCompactComponent listing={ensuredListing} isHorizontal={isHorizontal} {...rest} />
    </NamedLink>
  );
};
ListingCardCompact.displayName = 'ListingCardCompact';
export default injectIntl(ListingCardCompact);

// NamedLink component cannot be used outside of Router
// Some components, such as SearchMap, are global scoped
// Redux store is also not available (so we cannot use SaveListingButton)
const ListingCardCompactWithoutLinkComponent = props => {
  const { rootClassName, className, listing, ...rest } = props;
  const classes = useMemo(() => classNames(rootClassName || css.root, className), [
    className,
    rootClassName,
  ]);
  const ensuredListing = useMemo(() => ensureListing(listing), [listing]);

  return (
    <div className={classes}>
      <ListingCardCompactComponent listing={ensuredListing} showSave={false} {...rest} />
    </div>
  );
};
ListingCardCompactWithoutLinkComponent.displayName = 'ListingCardCompactWithoutLinkComponent';
export const ListingCardCompactWithoutLink = injectIntl(ListingCardCompactWithoutLinkComponent);
