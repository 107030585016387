import React, { useCallback, useMemo, useState } from 'react';
import { array, arrayOf, bool, func, number, object, string } from 'prop-types';
import { FormattedMessage, injectIntl, intlShape } from '../../../util/reactIntl';
import { propTypes } from '../../../util/types';
import {
  ensureListing,
  ensureTransaction,
  ensureUser,
  userDisplayNameAsString,
} from '../../../util/data';
import { isMobileSafari } from '../../../util/userAgent';
import {
  NamedLink,
  UserDisplayName,
  AuthorInfo,
  OrderPanel,
  ListingCardCompact,
  ErrorMessage,
} from '../../../components';

// These are internal components that make this file more readable.
import BreakdownMaybe from './BreakdownMaybe';
import FeedSection from './FeedSection';
import ActionButtons from './ActionButtons';
import PanelHeading, {
  HEADING_ENQUIRED,
  HEADING_PAYMENT_PENDING,
  HEADING_PAYMENT_EXPIRED,
  HEADING_REQUESTED,
  HEADING_ACCEPTED,
  HEADING_DECLINED,
  HEADING_CANCELED,
  HEADING_DELIVERED,
  HEADING_CANCELED_BY_CUSTOMER,
} from './PanelHeading';
import {
  hasCustomerCanceled,
  transitions,
  txHasBeenDelivered,
  txIsAccepted,
  txIsCanceled,
  txIsCanceledResolved,
  txIsCanceledUnresolved,
  txIsDeclined,
  txIsEnquired,
  txIsInFirstReviewBy,
  txIsPaymentExpired,
  txIsPaymentPending,
  txIsRequested,
  txIsReviewed,
  txIsStarted,
} from '../../../transactions/transactionProcessBooking';
import SendMessageForm from '../SendMessageForm/SendMessageForm';

import css from './TransactionPanel.module.css';
import ReviewModal from '../ReviewModal/ReviewModal';
import { useConfiguration } from '../../../context/configurationContext';
import ListingService from '../../../services/ListingService';

const getOtherUserProps = (currentUser, currentProvider, currentCustomer, intl) => {
  const authorDisplayName = <UserDisplayName user={currentProvider} intl={intl} hideUserType />;
  const customerDisplayName = <UserDisplayName user={currentCustomer} intl={intl} hideUserType />;

  let otherUser;
  let otherUserDisplayName = '';
  let otherUserDisplayNameString = '';
  const currentUserIsCustomer =
    currentUser.id && currentCustomer.id && currentUser.id.uuid === currentCustomer.id.uuid;
  const currentUserIsProvider =
    currentUser.id && currentProvider.id && currentUser.id.uuid === currentProvider.id.uuid;

  if (currentUserIsCustomer) {
    otherUser = currentProvider;
    otherUserDisplayName = authorDisplayName;
    otherUserDisplayNameString = userDisplayNameAsString(currentProvider, '');
  } else if (currentUserIsProvider) {
    otherUser = currentCustomer;
    otherUserDisplayName = customerDisplayName;
    otherUserDisplayNameString = userDisplayNameAsString(currentCustomer, '');
  }

  return {
    authorDisplayName,
    customerDisplayName,
    otherUser,
    otherUserDisplayName,
    otherUserDisplayNameString,
  };
};

const TransactionPanelComponent = props => {
  const {
    currentUser,
    transaction,
    totalMessagePages,
    oldestMessagePageFetched,
    messages: chatmessages,
    initialMessageFailed,
    savePaymentMethodFailed,
    fetchMessagesInProgress,
    fetchMessagesError,
    sendMessageInProgress,
    sendMessageError,
    sendReviewInProgress,
    sendReviewError,
    onManageDisableScrolling,
    onShowMoreMessages,
    transactionRole,
    intl,
    onAcceptSale,
    onDeclineSale,
    acceptInProgress,
    declineInProgress,
    acceptSaleError,
    declineSaleError,
    modifyBookingInProgress,
    cancelBookingInProgress,
    modifyBookingError,
    cancelBookingError,
    onModifyBooking,
    onCancelBooking,
    onReBook,
    onSubmitBookingRequest,
    monthlyTimeSlots,
    nextTransitions,
    onFetchTransactionLineItems,
    lineItems,
    fetchLineItemsInProgress,
    fetchLineItemsError,
    onSendReview,
    onSendMessage,

    bookings,
  } = props;

  const config = useConfiguration();

  const [isReviewModalOpen, setIsReviewModalOpen] = useState(false);

  const currentTransaction = useMemo(() => ensureTransaction(transaction), [transaction]);
  const currentListing = useMemo(() => ensureListing(currentTransaction.listing), [
    currentTransaction.listing,
  ]);
  const currentProvider = useMemo(() => ensureUser(currentTransaction.provider), [
    currentTransaction.provider,
  ]);
  const currentCustomer = useMemo(() => ensureUser(currentTransaction.customer), [
    currentTransaction.customer,
  ]);

  const { listingPublicData: savedListingPublicData } = useMemo(
    () => currentTransaction.attributes.protectedData || {},
    [currentTransaction]
  );

  const isCustomer = useMemo(() => transactionRole === 'customer', [transactionRole]);
  const isProvider = useMemo(() => transactionRole === 'provider', [transactionRole]);

  const { id, attributes } = useMemo(() => currentListing, [currentListing]);
  const { deleted } = useMemo(() => attributes, [attributes]);

  const listingLoaded = useMemo(() => !!id, [id]);
  const listingDeleted = useMemo(() => listingLoaded && deleted, [deleted, listingLoaded]);

  const iscustomerLoaded = useMemo(() => !!currentCustomer.id, [currentCustomer.id]);
  const isCustomerBanned = useMemo(() => iscustomerLoaded && currentCustomer.attributes.banned, [
    currentCustomer.attributes.banned,
    iscustomerLoaded,
  ]);
  const isCustomerDeleted = useMemo(() => iscustomerLoaded && currentCustomer.attributes.deleted, [
    currentCustomer.attributes.deleted,
    iscustomerLoaded,
  ]);
  const isProviderLoaded = useMemo(() => !!currentProvider.id, [currentProvider.id]);
  const isProviderBanned = useMemo(() => isProviderLoaded && currentProvider.attributes.banned, [
    currentProvider.attributes.banned,
    isProviderLoaded,
  ]);
  const isProviderDeleted = useMemo(() => isProviderLoaded && currentProvider.attributes.deleted, [
    currentProvider.attributes.deleted,
    isProviderLoaded,
  ]);

  const {
    authorDisplayName,
    customerDisplayName,
    otherUser,
    otherUserDisplayName,
    otherUserDisplayNameString,
  } = useMemo(() => getOtherUserProps(currentUser, currentProvider, currentCustomer, intl), [
    currentCustomer,
    currentProvider,
    currentUser,
    intl,
  ]);

  const showSendMessageForm = useMemo(
    () => !isCustomerBanned && !isCustomerDeleted && !isProviderBanned && !isProviderDeleted,
    [isCustomerBanned, isCustomerDeleted, isProviderBanned, isProviderDeleted]
  );

  const stateData = useMemo(() => {
    if (txIsEnquired(currentTransaction)) {
      const transitionsArray = Array.isArray(nextTransitions)
        ? nextTransitions.map(transition => transition.attributes.name)
        : [];
      const hasCorrectNextTransition =
        transitionsArray.length > 0 &&
        transitionsArray.includes(transitions.REQUEST_PAYMENT_AFTER_ENQUIRY);
      return {
        headingState: HEADING_ENQUIRED,
        showBookingPanel: isCustomer && !isProviderBanned && hasCorrectNextTransition,
      };
    } else if (txIsPaymentPending(currentTransaction)) {
      return {
        headingState: HEADING_PAYMENT_PENDING,
        showDetailCardHeadings: isCustomer,
      };
    } else if (txIsPaymentExpired(currentTransaction)) {
      return {
        headingState: HEADING_PAYMENT_EXPIRED,
        showDetailCardHeadings: isCustomer,
      };
    } else if (txIsRequested(currentTransaction)) {
      return {
        headingState: HEADING_REQUESTED,
        showDetailCardHeadings: isCustomer,
        showSaleButtons: isProvider && !isCustomerBanned,
        showModifyButton: isCustomer && !isProviderBanned,
        showCancelButton: isCustomer && !isProviderBanned,
      };
    } else if (txIsAccepted(currentTransaction)) {
      return {
        headingState: HEADING_ACCEPTED,
        showDetailCardHeadings: isCustomer,
        showModifyButton: isCustomer && !isProviderBanned,
        showCancelButton: (isCustomer && !isProviderBanned) || (isProvider && !isCustomerBanned),
      };
    } else if (txIsStarted(currentTransaction)) {
      return {
        headingState: HEADING_ACCEPTED,
        showDetailCardHeadings: isCustomer,
      };
    } else if (txIsDeclined(currentTransaction)) {
      return {
        headingState: HEADING_DECLINED,
        showDetailCardHeadings: isCustomer,
        showReBookButton: isCustomer && !isProviderBanned,
      };
    } else if (
      txIsCanceled(currentTransaction) ||
      txIsCanceledUnresolved(currentTransaction) ||
      txIsCanceledResolved(currentTransaction)
    ) {
      return {
        headingState: hasCustomerCanceled(currentTransaction.attributes.lastTransition)
          ? HEADING_CANCELED_BY_CUSTOMER
          : HEADING_CANCELED,
        showDetailCardHeadings: isCustomer,
        showReBookButton: isCustomer && !isProviderBanned,
      };
    } else if (txHasBeenDelivered(currentTransaction)) {
      const reviewPeriodIsOver = txIsReviewed(transaction);
      const userHasLeftAReview = txIsInFirstReviewBy(transaction, isCustomer);
      return {
        headingState: HEADING_DELIVERED,
        showDetailCardHeadings: isCustomer,
        showReviewButton: !(reviewPeriodIsOver || userHasLeftAReview),
      };
    } else {
      return { headingState: 'unknown' };
    }
  }, [
    currentTransaction,
    isCustomer,
    isCustomerBanned,
    isProvider,
    isProviderBanned,
    nextTransitions,
    transaction,
  ]);

  const messages = useMemo(
    () => ({
      detailsTitleBooked: intl.formatMessage(
        { id: 'TransactionPanel.detailsTitleBooked' },
        { otherUserDisplayName }
      ),
      detailsTitleContacted: intl.formatMessage(
        { id: 'TransactionPanel.detailsTitleContacted' },
        { otherUserDisplayName }
      ),
      detailsTitleYouBooked: intl.formatMessage(
        { id: 'TransactionPanel.detailsTitleYouBooked' },
        { otherUserDisplayName }
      ),
      detailsTitleYouContacted: intl.formatMessage(
        { id: 'TransactionPanel.detailsTitleYouContacted' },
        { otherUserDisplayName }
      ),
      detailsTitleYouAreBooking: intl.formatMessage(
        { id: 'TransactionPanel.detailsTitleYouAreBooking' },
        { otherUserDisplayName }
      ),
      deletedListingTitle: intl.formatMessage({ id: 'TransactionPanel.deletedListingTitle' }),
      savePaymentMethodFailed: intl.formatMessage(
        { id: 'TransactionPanel.savePaymentMethodFailed' },
        {
          paymentMethodsPageLink: (
            <NamedLink name="PaymentMethodsPage">
              <FormattedMessage id="TransactionPanel.paymentMethodsPageLink" />
            </NamedLink>
          ),
        }
      ),
      modifyButton: intl.formatMessage({ id: 'General.modify' }),
      cancelButton: intl.formatMessage({ id: 'General.cancel' }),
      acceptButton: intl.formatMessage({ id: 'General.accept' }),
      declineButton: intl.formatMessage({ id: 'General.decline' }),
      reBookButton: intl.formatMessage({ id: 'General.reBook' }),
      reviewButton: intl.formatMessage({ id: 'General.review' }),
    }),
    [intl, otherUserDisplayName]
  );

  // ---------------------------------------- Render blocks ---------------------------------------- //

  const bookingDetailsHeading = useMemo(
    () => (
      <h3 className={css.detailsTitle}>
        {isProvider &&
          (txIsEnquired(currentTransaction)
            ? messages.detailsTitleContacted
            : messages.detailsTitleBooked)}
        {isCustomer &&
          (txIsEnquired(currentTransaction)
            ? messages.detailsTitleYouContacted
            : txIsRequested(currentTransaction)
            ? messages.detailsTitleYouAreBooking
            : messages.detailsTitleYouBooked)}
      </h3>
    ),
    [currentTransaction, isCustomer, isProvider, messages]
  );

  const modifyCancelButtons = (stateData.showModifyButton || stateData.showCancelButton) && (
    <ActionButtons
      neutralInProgress={modifyBookingInProgress}
      declineInProgress={cancelBookingInProgress}
      neutralError={modifyBookingError}
      declineError={cancelBookingError}
      neutralButtonText={stateData.showModifyButton && messages.modifyButton}
      declineButtonText={stateData.showCancelButton && messages.cancelButton}
      onNeutral={() => onModifyBooking(currentTransaction.id, txIsAccepted(currentTransaction))}
      onDecline={() =>
        onCancelBooking(currentTransaction.id, isProvider, txIsAccepted(currentTransaction))
      }
    />
  );

  const saleButtons = stateData.showSaleButtons && (
    <ActionButtons
      acceptInProgress={acceptInProgress}
      declineInProgress={declineInProgress}
      acceptError={acceptSaleError}
      declineError={declineSaleError}
      acceptButtonText={messages.acceptButton}
      declineButtonText={messages.declineButton}
      onAccept={() => onAcceptSale(currentTransaction.id, id)}
      onDecline={() => onDeclineSale(currentTransaction.id)}
    />
  );

  const reBookReviewButtons = (stateData.showReBookButton || stateData.showReviewButton) && (
    <ActionButtons
      neutralButtonText={stateData.showReBookButton && messages.reBookButton}
      reviewButtonText={stateData.showReviewButton && messages.reviewButton}
      onNeutral={onReBook}
      onReview={() => setIsReviewModalOpen(true)}
    />
  );

  const listingTitle = deleted
    ? messages.deletedListingTitle
    : ListingService.createTitle(intl, currentListing);

  const sectionBookingPanelMaybe = stateData.showBookingPanel && (
    <OrderPanel
      isOwnListing={false}
      listing={currentListing}
      title={listingTitle}
      authorDisplayName={authorDisplayName}
      onSubmit={onSubmitBookingRequest}
      onManageDisableScrolling={onManageDisableScrolling}
      monthlyTimeSlots={monthlyTimeSlots}
      onFetchTransactionLineItems={onFetchTransactionLineItems}
      lineItems={lineItems}
      fetchLineItemsInProgress={fetchLineItemsInProgress}
      fetchLineItemsError={fetchLineItemsError}
    />
  );

  const sectionActionMaybe = (stateData.showModifyButton ||
    stateData.showCancelButton ||
    stateData.showSaleButtons ||
    stateData.showReBookButton ||
    stateData.showReviewButton) && (
    <section className={css.sectionDetails}>
      {modifyCancelButtons}
      {saleButtons}
      {reBookReviewButtons}
    </section>
  );

  // ---------------------------------------- Callbacks ---------------------------------------- //

  const onSubmitReview = useCallback(
    values => {
      const { reviewRating, reviewContent } = values;
      const rating = Number.parseInt(reviewRating, 10);

      onSendReview(transactionRole, currentTransaction, rating, reviewContent)
        .then(() => setIsReviewModalOpen(false))
        .catch(e => {});
    },
    [currentTransaction, onSendReview, transactionRole]
  );

  const onSendMessageFormFocus = useCallback(() => {
    if (isMobileSafari()) {
      // Scroll to bottom
      window.scroll({ top: document.body.scrollHeight, left: 0, behavior: 'smooth' });
    }
  }, []);

  const scrollToMessage = useCallback(messageId => {
    const selector = `#msg-${messageId.uuid}`;
    const el = document.querySelector(selector);
    if (el) {
      el.scrollIntoView({ block: 'start', behavior: 'smooth' });
    }
  }, []);

  const onMessageSubmit = useCallback(
    (values, form) => {
      const message = values.message ? values.message.trim() : null;
      if (!message) return;

      onSendMessage(currentTransaction.id, message)
        .then(messageId => {
          form.reset();
          scrollToMessage(messageId);
        })
        .catch(e => {});
    },
    [currentTransaction.id, onSendMessage, scrollToMessage]
  );

  // ---------------------------------------- Render ---------------------------------------- //

  return (
    <>
      <div className={css.sectionWrapper}>
        <ListingCardCompact
          className={css.listingCardDesktop}
          listing={currentListing}
          currentUser={currentUser}
          showPrice={false}
          showRating
          isHorizontal
          config={config}
        />
        <section className={css.section}>
          <PanelHeading
            panelHeadingState={stateData.headingState}
            transactionRole={transactionRole}
            providerName={authorDisplayName}
            customerName={customerDisplayName}
            isCustomerBanned={isCustomerBanned}
            listingDeleted={listingDeleted}
          />

          {savePaymentMethodFailed && (
            <ErrorMessage errorMessage={messages.savePaymentMethodFailed} />
          )}

          <FeedSection
            rootClassName={css.feedContainer}
            currentTransaction={currentTransaction}
            currentUser={currentUser}
            fetchMessagesError={fetchMessagesError}
            fetchMessagesInProgress={fetchMessagesInProgress}
            initialMessageFailed={initialMessageFailed}
            chatmessages={chatmessages}
            oldestMessagePageFetched={oldestMessagePageFetched}
            onOpenReviewModal={() => setIsReviewModalOpen(true)}
            onShowMoreMessages={() => onShowMoreMessages(currentTransaction.id)}
            totalMessagePages={totalMessagePages}
          />
          <SendMessageForm
            formId={'TransactionPanel.sendMessageForm'}
            rootClassName={css.sendMessageForm}
            inProgress={sendMessageInProgress}
            sendMessageError={sendMessageError}
            otherUserDisplayName={otherUserDisplayNameString}
            showSendMessageForm={showSendMessageForm}
            onFocus={onSendMessageFormFocus}
            onSubmit={onMessageSubmit}
          />
        </section>
      </div>

      <div className={css.sectionWrapperDetails}>
        <ListingCardCompact
          className={css.listingCardMobile}
          listing={currentListing}
          currentUser={currentUser}
          showPrice={false}
          showRating
          isHorizontal
          config={config}
        />
        <section className={css.sectionDetails}>
          {bookingDetailsHeading}
          <AuthorInfo author={otherUser} />
          <BreakdownMaybe
            transaction={currentTransaction}
            transactionRole={transactionRole}
            listing={currentListing}
            savedListingPublicData={savedListingPublicData}
            bookings={bookings}
          />
        </section>
        {sectionBookingPanelMaybe}
        {sectionActionMaybe}
      </div>

      <ReviewModal
        id="TransactionPage.reviewModal"
        isOpen={isReviewModalOpen}
        onCloseModal={() => setIsReviewModalOpen(false)}
        onManageDisableScrolling={onManageDisableScrolling}
        onSubmitReview={onSubmitReview}
        revieweeName={otherUserDisplayName}
        sendReviewInProgress={sendReviewInProgress}
        sendReviewError={sendReviewError}
      />
    </>
  );
};

TransactionPanelComponent.defaultProps = {
  rootClassName: null,
  className: null,
  currentUser: null,
  acceptSaleError: null,
  declineSaleError: null,
  fetchMessagesError: null,
  initialMessageFailed: false,
  savePaymentMethodFailed: false,
  sendMessageError: null,
  sendReviewError: null,
  monthlyTimeSlots: null,
  nextTransitions: null,
  lineItems: null,
  fetchLineItemsError: null,
};

TransactionPanelComponent.propTypes = {
  rootClassName: string,
  className: string,

  currentUser: propTypes.currentUser,
  transaction: propTypes.transaction.isRequired,
  totalMessagePages: number.isRequired,
  oldestMessagePageFetched: number.isRequired,
  messages: arrayOf(propTypes.message).isRequired,
  initialMessageFailed: bool,
  savePaymentMethodFailed: bool,
  fetchMessagesInProgress: bool.isRequired,
  fetchMessagesError: propTypes.error,
  sendMessageInProgress: bool.isRequired,
  sendMessageError: propTypes.error,
  sendReviewInProgress: bool.isRequired,
  sendReviewError: propTypes.error,
  onManageDisableScrolling: func.isRequired,
  onShowMoreMessages: func.isRequired,
  onSendMessage: func.isRequired,
  onSendReview: func.isRequired,
  onSubmitBookingRequest: func.isRequired,
  monthlyTimeSlots: object,
  nextTransitions: array,

  // Sale related props
  onAcceptSale: func.isRequired,
  onDeclineSale: func.isRequired,
  acceptInProgress: bool.isRequired,
  declineInProgress: bool.isRequired,
  acceptSaleError: propTypes.error,
  declineSaleError: propTypes.error,

  // line items
  onFetchTransactionLineItems: func.isRequired,
  lineItems: array,
  fetchLineItemsInProgress: bool.isRequired,
  fetchLineItemsError: propTypes.error,

  // from injectIntl
  intl: intlShape,
};

const TransactionPanel = injectIntl(TransactionPanelComponent);

export default TransactionPanel;
