import { useEffect } from 'react';
import PartnerService from '../services/PartnerService';

const useFontLoader = () => {
  useEffect(() => {
    if (typeof window === 'undefined') return;

    const { branding: { fonts } = {} } = PartnerService.partner || {};

    const {
      fontFamily = "'Comfortaa', Helvetica, Arial, sans-serif",
      fontStyle,
      fontWeight,
      fontDisplay,
      src,
    } = fonts || {};

    document.documentElement.style.setProperty('--fontFamily', fontFamily);

    if (src) {
      const link = document.createElement('link');

      link.setAttribute('rel', 'stylesheet');
      link.setAttribute('type', 'text/css');
      link.setAttribute('href', src);

      document.head.appendChild(link);
    }
  }, []);
};

export default useFontLoader;
