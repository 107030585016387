import React, { createContext, useContext, useEffect, useRef, useState } from 'react';
import { isFirefox, isInStandaloneMode, isIOS, isSamsungInternet } from '../util/userAgent';
import { useIntl } from 'react-intl';
import InstallPrompt from '../components/InstallPrompt/InstallPrompt';

export const InstallPromptContext = createContext();

export const InstallPromptProvider = props => {
  const { children, value } = props;

  const [installPrompt, setInstallPrompt] = useState(null);
  const [installForUnsupported, setInstallForUnsupported] = useState(null);
  const [hasClickedX, setHasClickedX] = useState(false);
  const hasShownPrompt = useRef(false);

  const intl = useIntl();

  useEffect(() => {
    const addInstallPrompt = async event => {
      event.preventDefault();

      if (hasShownPrompt.current) return;

      setInstallPrompt(event);

      hasShownPrompt.current = true;
    };

    window.addEventListener('beforeinstallprompt', addInstallPrompt);

    return () => {
      window.removeEventListener('beforeinstallprompt', addInstallPrompt);
    };
  }, []);

  useEffect(() => {
    if (isInStandaloneMode()) return;

    if (isIOS()) {
      setInstallForUnsupported(intl.formatMessage({ id: 'General.addToHomeScreenIOS' }));
    } else if (isSamsungInternet()) {
      setInstallForUnsupported(intl.formatMessage({ id: 'General.addToHomeScreenSamsung' }));
    } else if (isFirefox()) {
      setInstallForUnsupported(intl.formatMessage({ id: 'General.addToHomeScreenFirefox' }));
    }
  }, [intl]);

  return (
    <InstallPromptContext.Provider
      value={{
        ...value,
        installPrompt,
        installForUnsupported,
        hasClickedX,
        setInstallPrompt,
        setInstallForUnsupported,
        setHasClickedX,
      }}
    >
      {children}
      <InstallPrompt />
    </InstallPromptContext.Provider>
  );
};

export const useInstallPrompt = () => {
  return useContext(InstallPromptContext);
};
