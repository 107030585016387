import React from 'react';
import { IntlProvider } from 'react-intl';
import useTranslations from './hooks/useTranslations';

const TranslationsWrapper = props => {
  const { children } = props;

  const { localeMessages, locale } = useTranslations();

  return (
    <IntlProvider locale={locale} messages={localeMessages} textComponent="span">
      {children}
    </IntlProvider>
  );
};

export default TranslationsWrapper;
