// Order is important
export const DISCIPLINES_TO_SLIDE = [
  {
    key: 'alpineSkiing',
    queryParams: {
      pub_activity: 'has_any:skiing',
      pub_discipline: 'has_any:alpineSkiing',
      pub_skiing: 'has_any:alpineSkiing',
    },
    srcImg: '/static/imgs/disciplines/skiing/alpineSkiing.avif',
  },
  {
    key: 'snowShoeing',
    queryParams: {
      pub_activity: 'has_any:hiking',
      pub_discipline: 'has_any:snowShoeing',
      pub_hiking: 'has_any:snowShoeing',
    },
    srcImg: '/static/imgs/disciplines/hiking/snowShoeing.avif',
  },
  {
    key: 'crossCountrySkiing',
    queryParams: {
      pub_activity: 'has_any:skiing',
      pub_discipline: 'has_any:crossCountrySkiing',
      pub_skiing: 'has_any:crossCountrySkiing',
    },
    srcImg: '/static/imgs/disciplines/skiing/crossCountrySkiing.avif',
  },
  {
    key: 'skiTouring',
    queryParams: {
      pub_activity: 'has_any:skiing',
      pub_discipline: 'has_any:skiTouring',
      pub_skiing: 'has_any:skiTouring',
    },
    srcImg: '/static/imgs/disciplines/skiing/skiTouring.avif',
  },
  {
    key: 'iceClimbing',
    queryParams: {
      pub_activity: 'has_any:climbing',
      pub_discipline: 'has_any:iceClimbing',
      pub_climbing: 'has_any:iceClimbing',
    },
    srcImg: '/static/imgs/disciplines/climbing/iceClimbing.avif',
  },
  {
    key: 'huskySledding',
    queryParams: {
      pub_activity: 'has_any:sledging',
      pub_discipline: 'has_any:huskySledding',
      pub_sledging: 'has_any:huskySledding',
    },
    srcImg: '/static/imgs/disciplines/sledging/huskySledding.avif',
  },
  {
    key: 'freerideSkiing',
    queryParams: {
      pub_activity: 'has_any:skiing',
      pub_discipline: 'has_any:freerideSkiing',
      pub_skiing: 'has_any:freerideSkiing',
    },
    srcImg: '/static/imgs/disciplines/skiing/freerideSkiing.avif',
  },
  {
    key: 'freerideSnowboarding',
    queryParams: {
      pub_activity: 'has_any:snowboarding',
      pub_discipline: 'has_any:freerideSnowboarding',
      pub_snowboarding: 'has_any:freerideSnowboarding',
    },
    srcImg: '/static/imgs/disciplines/snowboarding/freerideSnowboarding.avif',
  },
  {
    key: 'fatBiking',
    queryParams: {
      pub_activity: 'has_any:biking',
      pub_discipline: 'has_any:fatBiking',
      pub_biking: 'has_any:fatBiking',
    },
    srcImg: '/static/imgs/disciplines/biking/fatBiking.avif',
  },
  {
    key: 'guidedWalking',
    queryParams: {
      pub_activity: 'has_any:otherExperiences',
      pub_discipline: 'has_any:guidedWalking',
      pub_otherExperiences: 'has_any:guidedWalking',
    },
    srcImg: '/static/imgs/disciplines/otherExperiences/guidedWalking.avif',
  },
  {
    key: 'tobogganing',
    queryParams: {
      pub_activity: 'has_any:sledging',
      pub_discipline: 'has_any:tobogganing',
      pub_sledging: 'has_any:tobogganing',
    },
    srcImg: '/static/imgs/disciplines/sledging/tobogganing.avif',
  },
  {
    key: 'onPisteSnowboarding',
    queryParams: {
      pub_activity: 'has_any:snowboarding',
      pub_discipline: 'has_any:onPisteSnowboarding',
      pub_snowboarding: 'has_any:onPisteSnowboarding',
    },
    srcImg: '/static/imgs/disciplines/snowboarding/onPisteSnowboarding.avif',
  },

  {
    key: 'telemarkSkiing',
    queryParams: {
      pub_activity: 'has_any:skiing',
      pub_discipline: 'has_any:telemarkSkiing',
      pub_skiing: 'has_any:telemarkSkiing',
    },
    srcImg: '/static/imgs/disciplines/skiing/telemarkSkiing.avif',
  },
  {
    key: 'splitBoarding',
    queryParams: {
      pub_activity: 'has_any:snowboarding',
      pub_discipline: 'has_any:splitBoarding',
      pub_snowboarding: 'has_any:splitBoarding',
    },
    srcImg: '/static/imgs/disciplines/snowboarding/splitBoarding.avif',
  },
  {
    key: 'avalancheTraining',
    queryParams: {
      pub_activity: 'has_any:otherExperiences',
      pub_discipline: 'has_any:avalancheTraining',
      pub_otherExperiences: 'has_any:avalancheTraining',
    },
    srcImg: '/static/imgs/disciplines/otherExperiences/avalancheTraining.avif',
  },
  {
    key: 'northernLights',
    queryParams: {
      pub_activity: 'has_any:otherExperiences',
      pub_discipline: 'has_any:northernLights',
      pub_otherExperiences: 'has_any:northernLights',
    },
    srcImg: '/static/imgs/disciplines/otherExperiences/northernLights.avif',
  },
  {
    key: 'culinaryTasting',
    queryParams: {
      pub_activity: 'has_any:otherExperiences',
      pub_discipline: 'has_any:culinaryTasting',
      pub_otherExperiences: 'has_any:culinaryTasting',
    },
    srcImg: '/static/imgs/disciplines/otherExperiences/culinaryTasting.avif',
  },
  {
    key: 'mountainHiking',
    queryParams: {
      pub_activity: 'has_any:hiking',
      pub_discipline: 'has_any:mountainHiking',
      pub_hiking: 'has_any:mountainHiking',
    },
    srcImg: '/static/imgs/disciplines/hiking/mountainHiking.avif',
  },
  {
    key: 'mountainBiking',
    queryParams: {
      pub_activity: 'has_any:biking',
      pub_discipline: 'has_any:mountainBiking',
      pub_biking: 'has_any:mountainBiking',
    },
    srcImg: '/static/imgs/disciplines/biking/mountainBiking.avif',
  },
  {
    key: 'rockClimbing',
    queryParams: {
      pub_activity: 'has_any:climbing',
      pub_discipline: 'has_any:rockClimbing',
      pub_climbing: 'has_any:rockClimbing',
    },
    srcImg: '/static/imgs/disciplines/climbing/rockClimbing.avif',
  },
  {
    key: 'paragliding',
    queryParams: {
      pub_activity: 'has_any:airSports',
      pub_discipline: 'has_any:paragliding',
      pub_airSports: 'has_any:paragliding',
    },
    srcImg: '/static/imgs/disciplines/airSports/paragliding.avif',
  },
  {
    key: 'canyoning',
    queryParams: {
      pub_activity: 'has_any:riverSports',
      pub_discipline: 'has_any:canyoning',
      pub_riverSports: 'has_any:canyoning',
    },
    srcImg: '/static/imgs/disciplines/riverSports/canyoning.avif',
  },
  {
    key: 'horseRiding',
    queryParams: {
      pub_activity: 'has_any:horseRiding',
      pub_discipline: 'has_any:horseRiding',
      pub_horseRiding: 'has_any:horseRiding',
    },
    srcImg: '/static/imgs/disciplines/horseRiding/horseRiding.avif',
  },
  {
    key: 'viaFerrata',
    queryParams: {
      pub_activity: 'has_any:climbing',
      pub_discipline: 'has_any:viaFerrata',
      pub_climbing: 'has_any:viaFerrata',
    },
    srcImg: '/static/imgs/disciplines/climbing/viaFerrata.avif',
  },
  {
    key: 'eBiking',
    queryParams: {
      pub_activity: 'has_any:biking',
      pub_discipline: 'has_any:eBiking',
      pub_biking: 'has_any:eBiking',
    },
    srcImg: '/static/imgs/disciplines/biking/eBiking.avif',
  },
  {
    key: 'kayaking',
    queryParams: {
      pub_activity: 'has_any:lakeAndSeaSports',
      pub_discipline: 'has_any:kayaking',
      pub_lakeAndSeaSports: 'has_any:kayaking',
    },
    srcImg: '/static/imgs/disciplines/lakeAndSeaSports/kayaking.avif',
  },
  {
    key: 'roadCycling',
    queryParams: {
      pub_activity: 'has_any:biking',
      pub_discipline: 'has_any:roadCycling',
      pub_biking: 'has_any:roadCycling',
    },
    srcImg: '/static/imgs/disciplines/biking/roadCycling.avif',
  },
  {
    key: 'trailRunning',
    queryParams: {
      pub_activity: 'has_any:running',
      pub_discipline: 'has_any:trailRunning',
      pub_running: 'has_any:trailRunning',
    },
    srcImg: '/static/imgs/disciplines/running/trailRunning.avif',
  },
  {
    key: 'rafting',
    queryParams: {
      pub_activity: 'has_any:riverSports',
      pub_discipline: 'has_any:rafting',
      pub_riverSports: 'has_any:rafting',
    },
    srcImg: '/static/imgs/disciplines/riverSports/rafting.avif',
  },
  {
    key: 'bouldering',
    queryParams: {
      pub_activity: 'has_any:climbing',
      pub_discipline: 'has_any:bouldering',
      pub_climbing: 'has_any:bouldering',
    },
    srcImg: '/static/imgs/disciplines/climbing/bouldering.avif',
  },
  {
    key: 'mountaineering',
    queryParams: {
      pub_activity: 'has_any:climbing',
      pub_discipline: 'has_any:mountaineering',
      pub_climbing: 'has_any:mountaineering',
    },
    srcImg: '/static/imgs/disciplines/climbing/mountaineering.avif',
  },
  {
    key: 'sailing',
    queryParams: {
      pub_activity: 'has_any:lakeAndSeaSports',
      pub_discipline: 'has_any:sailing',
      pub_lakeAndSeaSports: 'has_any:sailing',
    },
    srcImg: '/static/imgs/disciplines/lakeAndSeaSports/sailing.avif',
  },
  {
    key: 'bungeeJumping',
    queryParams: {
      pub_activity: 'has_any:airSports',
      pub_discipline: 'has_any:bungeeJumping',
      pub_airSports: 'has_any:bungeeJumping',
    },
    srcImg: '/static/imgs/disciplines/airSports/bungeeJumping.avif',
  },
  {
    key: 'yoga',
    queryParams: {
      pub_activity: 'has_any:otherExperiences',
      pub_discipline: 'has_any:yoga',
      pub_otherExperiences: 'has_any:yoga',
    },
    srcImg: '/static/imgs/disciplines/otherExperiences/yoga.avif',
  },
  {
    key: 'tennis',
    queryParams: {
      pub_activity: 'has_any:courtSports',
      pub_discipline: 'has_any:tennis',
      pub_courtSports: 'has_any:tennis',
    },
    srcImg: '/static/imgs/disciplines/courtSports/tennis.avif',
  },
  {
    key: 'padel',
    queryParams: {
      pub_activity: 'has_any:courtSports',
      pub_discipline: 'has_any:padel',
      pub_courtSports: 'has_any:padel',
    },
    srcImg: '/static/imgs/disciplines/courtSports/padel.avif',
  },
];
