import { getLocaleFromUrl, LOCALE_TO_LANG } from '../constants/locales';
import { USER_TYPE } from '../constants/userAttributes';
import { getDefaultTimeZoneOnBrowser } from './dates';
import { createSlug } from './urlHelpers';

export const getUserType = user => {
  const { attributes } = user || {};

  const { profile } = attributes || {};

  const { publicData, metadata } = profile || {};

  const { isCompany } = publicData || {};

  const { userType } = metadata || {};

  if (isCompany) return USER_TYPE.BUSINESS;

  return userType || USER_TYPE.USER;
};

export const getDefaultTimeZone = () =>
  typeof window !== 'undefined' ? getDefaultTimeZoneOnBrowser() : 'Etc/UTC';

export const DEFAULT_AVAILABILITY_PLAN = {
  type: 'availability-plan/time',
  timezone: getDefaultTimeZone(),
  entries: [
    { dayOfWeek: 'mon', startTime: '00:00', endTime: '00:00', seats: 1000000 },
    { dayOfWeek: 'tue', startTime: '00:00', endTime: '00:00', seats: 1000000 },
    { dayOfWeek: 'wed', startTime: '00:00', endTime: '00:00', seats: 1000000 },
    { dayOfWeek: 'thu', startTime: '00:00', endTime: '00:00', seats: 1000000 },
    { dayOfWeek: 'fri', startTime: '00:00', endTime: '00:00', seats: 1000000 },
    { dayOfWeek: 'sat', startTime: '00:00', endTime: '00:00', seats: 1000000 },
    { dayOfWeek: 'sun', startTime: '00:00', endTime: '00:00', seats: 1000000 },
  ],
};

export const getListingDescription = description => {
  if (!description) return '';

  if (typeof description === 'string') return description;

  const { english, italian, french, german } = description || {};

  return english || italian || french || german;
};

export const createURLParamsForListing = listing => {
  const emptyParams = {
    id: 'id',
    slug: 'slug',
    location: 'location',
    author: 'author',
  };

  if (!listing?.id) return emptyParams;

  const { id, attributes, author } = listing;

  const { title, deleted, publicData } = attributes || {};

  const { meetingLocation, multiLangAddressParts, titles } = publicData || {};

  if (deleted) return emptyParams;

  const locale = getLocaleFromUrl();

  const language = LOCALE_TO_LANG[locale];

  const titleSlug = createSlug(titles?.[language] || title);

  const localizedLanguageParts = multiLangAddressParts?.[language];

  const localizedLocation = createLocationFromAddressParts(localizedLanguageParts);

  const locationSlug = createSlug(localizedLocation || meetingLocation || 'location');

  const authorSlug = createSlug(author?.attributes?.profile?.displayName || 'author');

  return { id: id.uuid, slug: titleSlug, location: locationSlug, author: authorSlug };
};

export const createLocationFromAddressParts = addressParts => {
  return addressParts ? `${addressParts.country}-${addressParts.region}-${addressParts.city}` : '';
};
