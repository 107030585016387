import { LISTING_TYPE } from '../constants/listingAttributes';
import { LANGUAGE_CODES, LOCALE_TO_LANG } from '../constants/locales';

/* eslint-disable import/no-anonymous-default-export */
export default {
  titleMessages: {},

  saveTitleMessages(lang, messages) {
    this.titleMessages[lang] = Object.entries(messages).reduce((generalMsgs, entry) => {
      const [transKey, transVal] = entry;

      if (transKey.includes('General.')) {
        generalMsgs[transKey] = transVal;
      }

      return generalMsgs;
    }, {});
  },

  getDataForTitle(listing) {
    const { attributes } = listing || {};
    const { publicData } = attributes || {};
    const { discipline, type, ability } = publicData || {};

    return { discipline, type, ability };
  },

  createTitle(intl, listing, forcedLangCode = null) {
    const { discipline, ability, type } = this.getDataForTitle(listing);

    let title = '';

    if (discipline) {
      const msgId = `General.${discipline}`;

      const translatedDiscipline =
        this.titleMessages[forcedLangCode]?.[msgId] || intl.formatMessage({ id: msgId });

      title += ' ' + translatedDiscipline.toLowerCase();
    }

    if (type === LISTING_TYPE.FREE) {
      const msgId = `General.freeSharing`;

      const translatedType =
        this.titleMessages[forcedLangCode]?.[msgId] || intl.formatMessage({ id: msgId });

      title += ' ' + translatedType.toLowerCase();
    } else if (type) {
      const msgId = `General.${type}`;

      const translatedType =
        this.titleMessages[forcedLangCode]?.[msgId] || intl.formatMessage({ id: msgId });

      title += ' ' + translatedType.toLowerCase();
    }

    title += ' -';

    if (ability) {
      const msgId = `General.${ability}Selected`;

      const translatedAbility =
        this.titleMessages[forcedLangCode]?.[msgId] || intl.formatMessage({ id: msgId });

      title += ' ' + translatedAbility.toLowerCase();
    }

    return title.replace(/\s\s+/, ' ').trim();
  },

  createMultiLangTitles(intl, listing) {
    return LANGUAGE_CODES.reduce((titles, lang) => {
      return { ...titles, [LOCALE_TO_LANG[lang]]: this.createTitle(intl, listing, lang) };
    }, {});
  },
};
