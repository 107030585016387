import React from 'react';
import { IconClose, InlineTextButton, Logo } from '..';
import { useIntl } from 'react-intl';

import { useConfiguration } from '../../context/configurationContext';
import classNames from 'classnames';
import { useInstallPrompt } from '../../context/installPromptContext';
import css from './InstallPrompt.module.css';

const InstallPrompt = props => {
  const { isInline, className } = props;

  const intl = useIntl();
  const config = useConfiguration();

  const {
    installPrompt,
    installForUnsupported,
    setInstallPrompt,
    hasClickedX,
    setHasClickedX,
  } = useInstallPrompt();

  const hidePrompt = hasClickedX && !isInline;

  return !hidePrompt && (installPrompt || installForUnsupported) ? (
    <div
      className={classNames(
        css.root,
        {
          [css.installApp]: !isInline,
          [css.installAppInline]: isInline,
        },
        className
      )}
    >
      {isInline && (
        <div>
          <b>{intl.formatMessage({ id: 'General.installWebApp' })}</b>
        </div>
      )}
      {!isInline && <Logo format="mobile" />}

      {installPrompt ? (
        <InlineTextButton
          type="button"
          onClick={() => {
            installPrompt.prompt();

            if (!isInline) setInstallPrompt(null);
          }}
          className={classNames(css.installAppTitle, { [css.installAppTitleBig]: isInline })}
        >
          {isInline
            ? intl.formatMessage({ id: 'General.install' })
            : intl.formatMessage(
                { id: 'General.addToHomeScreen' },
                { marketplaceName: config.marketplaceName }
              )}
        </InlineTextButton>
      ) : (
        <div className={css.installAppTitle}>
          {!isInline && (
            <>
              {intl.formatMessage(
                { id: 'General.addToHomeScreen' },
                { marketplaceName: config.marketplaceName }
              )}
              <br />
            </>
          )}
          <div className={css.installNote}>{installForUnsupported}</div>
        </div>
      )}
      {!isInline && (
        <button
          type="button"
          onClick={() => {
            setHasClickedX(true);
          }}
          className={css.closeButton}
        >
          <IconClose />
        </button>
      )}
    </div>
  ) : null;
};

export default InstallPrompt;
