import React, { useMemo, useState } from 'react';
import { string } from 'prop-types';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import classNames from 'classnames';
import { twitterPageURL } from '../../util/urlHelpers';
import {
  IconSocialMediaFacebook,
  IconSocialMediaInstagram,
  IconSocialMediaTwitter,
  Logo,
  ExternalLink,
  NamedLink,
  LanguageSelect,
} from '../../components';
import IconSocialMediaLinkedIn from '../IconSocialMediaLinkedIn/IconSocialMediaLinkedIn';
import ModalContainer from '../ModalContainer/ModalContainer';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';

import { useConfiguration } from '../../context/configurationContext';
import defaultLocations from '../../config/configDefaultLocationSearches';
import IconSwistainableLogo from '../SectionPartners/logos/IconSwistainableLogo';
import { youtubeIcon } from '../../containers/PageBuilder/Primitives/Link/Icons';
import { useSelector } from 'react-redux';
import IconLogo from '../Logo/IconLogo';
import InstallPrompt from '../InstallPrompt/InstallPrompt';

import css from './Footer.module.css';

const MODAL_TYPE = {
  CHANGE_LANGUAGE: 'changeLanguage',
};

const renderSocialMediaLinks = (intl, config) => {
  const {
    siteFacebookPage,
    siteInstagramPage,
    siteTwitterHandle,
    siteLinkedinPage,
    siteYouTubePage,
  } = config;
  const siteTwitterPage = twitterPageURL(siteTwitterHandle);

  const twitterLink = siteTwitterPage ? (
    <li key="linkToTwitter" className={css.icon}>
      <ExternalLink
        href={siteTwitterPage}
        className={css.icon}
        title={intl.formatMessage({ id: 'Footer.goToTwitter' })}
      >
        <IconSocialMediaTwitter />
      </ExternalLink>
    </li>
  ) : null;

  const facebookLink = siteFacebookPage ? (
    <li key="linkToFacebook" className={css.icon}>
      <ExternalLink
        href={siteFacebookPage}
        className={css.icon}
        title={intl.formatMessage({ id: 'Footer.goToFacebook' })}
      >
        <IconSocialMediaFacebook />
      </ExternalLink>
    </li>
  ) : null;

  const instragramLink = siteInstagramPage ? (
    <li key="linkToInstagram" className={css.icon}>
      <ExternalLink
        href={siteInstagramPage}
        className={css.icon}
        title={intl.formatMessage({ id: 'Footer.goToInstagram' })}
      >
        <IconSocialMediaInstagram />
      </ExternalLink>
    </li>
  ) : null;

  const linkedinLink = siteLinkedinPage ? (
    <li key="linkToLinkedin" className={css.icon}>
      <ExternalLink
        href={siteLinkedinPage}
        className={css.icon}
        title={intl.formatMessage({ id: 'Footer.goToLinkedin' })}
      >
        <IconSocialMediaLinkedIn />
      </ExternalLink>
    </li>
  ) : null;

  const youTubeLink = siteYouTubePage ? (
    <li key="linkToYouTube" className={css.icon}>
      <ExternalLink
        href={siteYouTubePage}
        className={css.icon}
        title={intl.formatMessage({ id: 'Footer.goToYouTube' })}
      >
        {youtubeIcon()}
      </ExternalLink>
    </li>
  ) : null;

  return [twitterLink, facebookLink, instragramLink, linkedinLink, youTubeLink].filter(Boolean);
};

const Footer = props => {
  const { rootClassName, className, location, intl } = props;

  const config = useConfiguration();

  const { interfaceLang } = useSelector(state => state.ui);

  const [modalTypeOpen, setModalTypeOpen] = useState(null);

  const { partner } = useSelector(state => state.ui);

  const socialMediaLinks = useMemo(() => renderSocialMediaLinks(intl, config), [intl, config]);

  const langWithoutLocale = interfaceLang?.split('-')?.[0] || 'en';

  const classes = useMemo(() => classNames(rootClassName || css.root, className), [
    className,
    rootClassName,
  ]);

  return (
    <>
      <div className={css.installAppWrapper}>
        <InstallPrompt isInline className={css.installApp} />
      </div>
      <div className={classes}>
        <div className={css.topBorderWrapper}>
          <div className={css.content}>
            <div className={css.links}>
              <div className={css.logoWrapper} id="organization">
                <NamedLink name="LandingPage" className={css.logoLink}>
                  <Logo format="mobile" className={css.logo} />
                </NamedLink>
              </div>
              <div className={css.linksWrapper}>
                <div className={css.organizationInfo}>
                  <div className={css.infoWrapper}>
                    <p className={css.organizationDescription}>
                      <FormattedMessage id="Footer.organizationDescription" />
                    </p>
                    <p className={css.organizationCopyright}>
                      <NamedLink name="LandingPage" className={css.copyrightLink}>
                        <FormattedMessage
                          id="Footer.copyright"
                          values={{ marketplaceName: config.marketplaceName }}
                        />
                      </NamedLink>
                    </p>
                  </div>
                </div>
                <div className={css.infoLinks}>
                  <ul className={css.list}>
                    <li className={css.listItem}>
                      <NamedLink name="NewListingPage" className={css.link}>
                        <FormattedMessage id="General.addActivity" />
                      </NamedLink>
                    </li>
                    <li className={css.listItem}>
                      <NamedLink
                        name="SearchPage"
                        to={{ search: defaultLocations[0].search }}
                        className={css.link}
                      >
                        <FormattedMessage id="Footer.toBrowseAnActivityPage" />
                      </NamedLink>
                    </li>
                    <li className={css.listItem}>
                      <NamedLink
                        name="CMSPage"
                        params={{ pageId: langWithoutLocale + '-stories' }}
                        className={css.link}
                      >
                        <FormattedMessage id="Footer.blogs" />
                      </NamedLink>
                    </li>
                  </ul>
                </div>
                <div className={css.searches}>
                  <ul className={css.list}>
                    <li className={css.listItem}>
                      <NamedLink name="HelpCenterBasePage" className={css.link}>
                        <FormattedMessage id="General.help" />
                      </NamedLink>
                    </li>
                    <li className={css.listItem}>
                      <NamedLink name="ContactPage" className={css.link}>
                        <FormattedMessage id="Footer.toContactPage" />
                      </NamedLink>
                    </li>
                    <li className={css.listItem}>
                      <button
                        className={css.buttonAsLink}
                        onClick={() => setModalTypeOpen(MODAL_TYPE.CHANGE_LANGUAGE)}
                      >
                        <FormattedMessage id="Footer.changeLanguage" />
                      </button>
                    </li>
                  </ul>
                </div>
                <ExternalLink
                  href={partner ? 'https://crion.org' : 'https://www.stnet.ch/de/swisstainable/'}
                  className={classNames(css.partnerLogo, { [css.crionAsPartner]: partner })}
                >
                  {partner ? (
                    <IconLogo format="mobile" className={css.logo} />
                  ) : (
                    <IconSwistainableLogo />
                  )}
                </ExternalLink>
                <div className={css.extraLinks}>
                  <ul className={css.tosAndPrivacy}>
                    {socialMediaLinks}
                    <li>
                      <NamedLink name="TermsAndConditionsPage" className={css.legalLink}>
                        <FormattedMessage id="Footer.termsOfUse" />
                      </NamedLink>
                    </li>
                    <li>
                      <NamedLink name="PrivacyPolicyPage" className={css.legalLink}>
                        <FormattedMessage id="Footer.privacyPolicy" />
                      </NamedLink>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ModalContainer
          id="ChangeLanguageCurrencyModal"
          title={!!modalTypeOpen && intl.formatMessage({ id: `Footer.${modalTypeOpen}` })}
          isModalOpen={!!modalTypeOpen}
          onClose={() => setModalTypeOpen(null)}
        >
          {modalTypeOpen === MODAL_TYPE.CHANGE_LANGUAGE && (
            <LanguageSelect location={location} embedded />
          )}
        </ModalContainer>
      </div>
    </>
  );
};

Footer.defaultProps = {
  rootClassName: null,
  className: null,
};

Footer.propTypes = {
  rootClassName: string,
  className: string,
  intl: intlShape.isRequired,
};

export default compose(
  withRouter,
  injectIntl
)(Footer);
